export default {
    prefix: 'custom',
    iconName: 'equipment',
    icon: [
        32.248,
        32.247,
        [],
        null,
        'M26.647 0H5.602A5.608 5.608 0 000 5.601v21.045a5.609 5.609 0 005.602 5.601h5.699a.73.73 0 000-1.458H5.602a4.147 4.147 0 01-4.143-4.143V5.6a4.147 4.147 0 014.143-4.143h21.045A4.148 4.148 0 0130.79 5.6v21.045a4.148 4.148 0 01-4.143 4.143h-9.793V26.91h1.502a.725.725 0 00.707-.557l1.006-4.114 2.52-2.458a.727.727 0 00.219-.521v-1.893a1.876 1.876 0 001.488-1.834v-1.768c0-1.033-.84-1.874-1.873-1.874h-1.787V7.037a.73.73 0 00-1.458 0v4.854h-6.107V7.037a.73.73 0 00-1.459 0v4.854H9.827a1.877 1.877 0 00-1.875 1.874v1.768a1.88 1.88 0 001.488 1.834v1.893c0 .196.08.384.221.521l2.52 2.458 1.004 4.114a.73.73 0 00.709.557h1.502v4.607a.73.73 0 00.729.729h10.523c3.088 0 5.6-2.513 5.6-5.601V5.6A5.607 5.607 0 0026.647 0zM9.411 13.765c0-.229.186-.415.416-.415h12.596c.229 0 .416.187.416.415v1.768a.417.417 0 01-.416.415H9.827a.416.416 0 01-.416-.415zm4.136 7.925a.737.737 0 00-.199-.349l-2.449-2.389v-1.545h10.449v1.545l-2.447 2.39a.713.713 0 00-.199.349l-.918 3.761h-3.318zm4.133-1.82a.73.73 0 00-.73-.729h-1.652a.73.73 0 000 1.458h1.652c.404 0 .73-.326.73-.729z'
    ]
};
