export default {
    prefix: 'custom',
    iconName: 'phone',
    icon: [
        6.35,
        5.3,
        [],
        null,
        'M4.097 2.39a.132.132 0 01-.132-.133v-.529a.132.132 0 01.265 0v.53a.132.132 0 01-.133.131zm-1.587 0a.132.132 0 01-.132-.133v-.529a.132.132 0 01.264 0v.53a.132.132 0 01-.132.131zm3.114-.164a.402.402 0 01-.19-.048 4.403 4.403 0 00-.663-.31c-.178-.06-.284-.24-.242-.408l.053-.196a.125.125 0 00-.014-.096c-.085-.114-.832-.205-1.393-.205-.541 0-1.272.096-1.353.208a.127.127 0 00-.013.097l.052.195c.042.169-.064.348-.242.41a9.4 9.4 0 00-.567.23c-.265.115-.286.123-.326.123a.328.328 0 01-.283-.144l-.405-.636a.256.256 0 01-.028-.208c.032-.123.13-.207.23-.28C1.055.368 2.18 0 3.175 0c.958 0 2.082.367 2.933.956.09.063.199.152.233.281v.002a.258.258 0 01-.029.207l-.405.636a.335.335 0 01-.283.144zM3.175.265c-.928 0-2.02.356-2.78.908-.032.023-.117.084-.129.13l.4.636c.007.011.024.019.045.022.04-.015.129-.054.237-.1a9.22 9.22 0 01.585-.238c.051-.018.08-.064.072-.093l-.052-.195a.392.392 0 01.043-.3C1.794.701 3.12.697 3.176.697c.145 0 1.426.01 1.62.334a.39.39 0 01.042.3l-.052.195c-.007.028.02.073.072.092.253.087.464.2.688.319.063.034.123.026.139.001l.405-.636-.006.001c-.006-.023-.033-.065-.126-.13-.797-.552-1.89-.91-2.783-.91zm.041 4.425c-.62 0-1.126-.427-1.126-.953s.505-.954 1.126-.954c.621 0 1.126.428 1.126.954 0 .525-.505.953-1.126.953zm0-1.642c-.474 0-.861.31-.861.689 0 .38.387.689.861.689.475 0 .862-.31.862-.689 0-.38-.387-.689-.862-.689zM4.626 5.3h-2.91c-.03 0-.645-.196-.678-.377-.002-.007-.122-.774-.077-.876l.63-1.833a.132.132 0 01.126-.09h2.91c.055 0 .104.034.123.086l.71 1.84c.021.05.03.111.024.17a9.442 9.442 0 01-.103.703c-.023.121-.145.186-.182.203-.534.174-.547.174-.572.174zm-2.892-.265h2.893c.007-.005.255-.083.476-.155 0 0 .019-.015.024-.021.049-.271.082-.557.094-.667a.08.08 0 00-.006-.042l-.68-1.76H1.812l-.604 1.753c.001.045.052.5.084.693.087.056.361.173.443.2z'
    ]
};
