import $ from 'jquery';
import { gsap, TweenLite, CSSPlugin, Power3 } from 'gsap';
import throttle from 'lodash/throttle';

gsap.registerPlugin(CSSPlugin);

const scroll = () => {
    if ($(window).scrollTop() > 100) {
        $('html').addClass('scrolled');
        // $('.col-header-right').trigger('tween');
    } else {
        $('html').removeClass('scrolled');
        // $('.col-header-right').trigger('tween');
    }
};

const setContentOffset = () => {
    $('header.is-sticky')
        .next()
        .css({ 'margin-top': $('header.is-sticky').outerHeight() });
};

$(() => {
    setContentOffset();
    scroll();

    $(window).on('resize', throttle(setContentOffset, 300));
    $(window).on('scroll', scroll);

    // const nodes = document.querySelectorAll('.col-header-right > *');
    // const total = nodes.length;
    // const ease = Power3.easeOut;
    // const boxes = [];

    // // eslint-disable-next-line no-plusplus
    // for (let i = 0; i < total; i++) {
    //     const node = nodes[i];

    //     // Initialize transforms on node
    //     TweenLite.set(node, { x: 0 });

    //     // console.log(gsap.getProperty(node, 'transform'));

    //     boxes[i] = {
    //         // eslint-disable-next-line no-underscore-dangle
    //         transform: {
    //             x: gsap.getProperty(node, 'x'),
    //             y: gsap.getProperty(node, 'y')
    //         },
    //         x: node.offsetLeft,
    //         y: node.offsetTop,
    //         node
    //     };
    // }

    // $('.col-header-right').on('tween', () => {
    //     // $('html').toggleClass('scrolled');

    //     // eslint-disable-next-line no-plusplus
    //     for (let i = 0; i < total; i++) {
    //         const box = boxes[i];

    //         const lastX = box.x;
    //         const lastY = box.y;

    //         box.x = box.node.offsetLeft;
    //         box.y = box.node.offsetTop;

    //         // Continue if box hasn't moved
    //         // eslint-disable-next-line no-continue
    //         if (lastX === box.x && lastY === box.y) continue;

    //         // Reversed delta values taking into account current transforms
    //         const x = box.transform.x + lastX - box.x;
    //         const y = box.transform.y + lastY - box.y;

    //         // Tween to 0 to remove the transforms
    //         TweenLite.fromTo(box.node, 0.5, { x, y }, { x: 0, y: 0, ease });
    //     }
    // });
});
