import $ from 'jquery';
import Swiper from 'swiper';

$(() => {
    if ($('.hero-home').length) {
        let homeSlider = new Swiper('.home-slider.swiper-container', {
            autoplay: {
                delay: 4000
            },
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
                loadOnTransitionStart: true
            },
            grabCursor: true,
            on: {
                init: () => {
                    $('.home-slider.swiper-container')
                        .find('.slide-title')
                        .addClass('show');

                    setTimeout(() => {
                        $('.home-slider.swiper-container')
                            .find('.slide-subtitle')
                            .addClass('show');
                    }, 300);
                }
            }
        });
    }

    if ($('.hero-page').length) {
        $('.hero-page')
            .find('.slide-title')
            .addClass('show');

        setTimeout(() => {
            $('.hero-page')
                .find('.slide-subtitle')
                .addClass('show');
        }, 300);
    }
});
