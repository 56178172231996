export default {
    prefix: 'custom',
    iconName: 'experience',
    icon: [
        10.76,
        10.22,
        [],
        null,
        'M9.19 1.4c.04 0 .08-.02.11-.04L9.95 1a.23.23 0 0 0-.22-.4l-.65.38a.23.23 0 0 0 .1.42zm1.34 1.74h-.75a.23.23 0 0 0 0 .45h.75a.23.23 0 0 0 0-.45zm-.58 2.6l-.65-.37a.23.23 0 0 0-.22.4l.65.37a.23.23 0 0 0 .23-.39zm-8.5-.37l-.65.38a.23.23 0 1 0 .23.39l.65-.38a.23.23 0 0 0-.23-.39zm-.25-2c0-.13-.1-.23-.22-.23H.23a.23.23 0 0 0 0 .45h.75c.12 0 .22-.1.22-.22zm.48-2.4L1.03.6A.23.23 0 0 0 .8.99l.66.37a.22.22 0 0 0 .3-.08.23.23 0 0 0-.08-.3zm5.05 1.88h-2.7a.23.23 0 0 0-.15.4l1.27 1.1v1.81a.23.23 0 1 0 .45 0V4.34l1.28-1.1a.23.23 0 0 0-.15-.4zm-1.35 1.1l-.75-.65h1.5zm0-3.95a3.37 3.37 0 0 0-1.95 6.11v2.63c0 .57.46 1.03 1.03 1.03h.28a.68.68 0 0 0 1.28 0h.28c.57 0 1.03-.46 1.03-1.03V6.1A3.36 3.36 0 0 0 5.38 0zm1.5 8.74c0 .32-.26.58-.58.58H4.46a.58.58 0 0 1-.58-.58v-.06h1.95a.23.23 0 1 0 0-.45H3.88v-.64h3zm.1-2.93a.23.23 0 0 0-.1.18v1.15h-3V5.99a.23.23 0 0 0-.1-.18 2.91 2.91 0 1 1 3.2 0z'
    ]
};
