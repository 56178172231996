import $ from 'jquery';
import { config, library, dom } from '@fortawesome/fontawesome-svg-core';

import {
    faBars,
    faCommentLines,
    faSearchPlus,
    faAngleLeft,
    faAngleRight as faAngleRightRegular
} from '@fortawesome/pro-regular-svg-icons';

import {
    // concrete5 icons - don't remove.
    faArrows,
    faShareAlt,
    faAngleDown,
    faArrowRight,
    faArrowLeft,
    faSpinnerThird,
    faTimes,
    faHome,
    faAngleRight as faAngleRightSolid
} from '@fortawesome/pro-solid-svg-icons';

import speechBubble from './icons/speech-bubble';
import experience from './icons/experience';
import quality from './icons/quality';
import variety from './icons/variety';
import phone from './icons/phone';
import clockTea from './icons/clock-tea';
import switchgear from './icons/switchgear';
import equipment from './icons/equipment';
import service from './icons/service';
import implementation from './icons/implementation';

// config.autoReplaceSvg = 'nest';
config.searchPseudoElements = true;

library.add(
    faArrows,
    faShareAlt,
    faBars,
    faCommentLines,
    faSearchPlus,
    faAngleLeft,
    faAngleRightRegular,
    faAngleRightSolid,
    faAngleDown,
    faArrowRight,
    faArrowLeft,
    faSpinnerThird,
    faTimes,
    faHome,
    speechBubble,
    experience,
    quality,
    variety,
    phone,
    clockTea,
    switchgear,
    equipment,
    service,
    implementation
);

// dom.i2svg({node: $('.ccm-page').get(0)});

dom.watch({
    autoReplaceSvgRoot: $('.ccm-page').get(0),
    observeMutationsRoot: $('.ccm-page').get(0)
});

export { dom };
