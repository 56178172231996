export default {
    prefix: 'custom',
    iconName: 'implementation',
    icon: [
        9.775,
        9.278,
        [],
        null,
        'M9.775 3.622a.493.493 0 00-.3-.453V1.174a.193.193 0 00-.193-.192H7.826a.193.193 0 000 .385H9.09V3.17a.493.493 0 000 .907v2.815H6.881a2.03 2.03 0 00-1.8-1.656v-1.42h.795a.494.494 0 100-.385h-.795V1.368h1.263c.06 0 .116-.028.153-.075L7.255.31A.193.193 0 106.95.075l-.7.907H3.525l-.7-.907a.193.193 0 10-.306.236l.759.982a.192.192 0 00.153.075h1.263V3.43h-.796a.494.494 0 100 .385h.796v1.42A2.031 2.031 0 002.894 6.89H.686V4.076a.493.493 0 000-.907V1.368H1.95a.193.193 0 000-.386H.493a.193.193 0 00-.193.193v1.994a.493.493 0 000 .907v3.008c0 .106.086.193.193.193h2.369a2.028 2.028 0 002.025 2 2.028 2.028 0 002.026-2h2.369a.193.193 0 00.193-.193V4.076c.176-.075.3-.25.3-.454zm-4.887 5.27c-.905 0-1.64-.736-1.64-1.64 0-.905.735-1.641 1.64-1.641.905 0 1.64.736 1.64 1.64 0 .905-.736 1.64-1.64 1.64zm.429-1.833h-.559l.306-.678a.193.193 0 10-.352-.159l-.429.95a.193.193 0 00.176.272h.559l-.306.678a.193.193 0 00.352.158l.429-.95a.192.192 0 00-.176-.271z'
    ]
};
