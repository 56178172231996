/**
 * Smooth scrolls the page to the `#a-{hash}` element.
 * Works also when coming from another page.
 */
import $ from 'jquery';
import 'jquery.easing';

const scroll = element => {
    if ($(element).length) {
        let offsetTop = $(element).offset().top;

        // Consider the sticky header.
        if ($('.is-sticky').length) {
            offsetTop -= $('.is-sticky').outerHeight();
        }

        // Consider the concrete5 toolbar.
        if ($('html').hasClass('ccm-toolbar-visible')) {
            offsetTop -= $('#ccm-toolbar').outerHeight();
        }

        $('html, body').animate(
            {
                scrollTop: offsetTop
            },
            750,
            'easeOutQuart'
        );
    }
};

$(() => {
    // Jump, if clicked element has a corresponding anchor on this page.
    $('a[href*="#"]').on('click', e => {
        const selector = `#a-${$(e.currentTarget)
            .attr('href')
            .split('#')
            .pop()}`;

        if ($(selector).length) {
            e.preventDefault();
            scroll($(selector).get(0));
        }
    });

    // Jump, if ID in hash passed in current location, has a corresponding anchor on this page.
    if (
        window.location.hash &&
        $(`#a-${window.location.hash.split('#').pop()}`).length
    ) {
        setTimeout(() => {
            scroll($(`#a-${window.location.hash.split('#').pop()}`).get(0));
        }, 750);
    }
});

export default scroll;
