import $ from 'jquery';
import bsCustomFileInput from 'bs-custom-file-input';

$(() => {
    bsCustomFileInput.init();

    $('.needs-validation').on('submit', event => {
        const form = event.target;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        $(form).addClass('was-validated');
    });
});
