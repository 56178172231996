export default {
    prefix: 'custom',
    iconName: 'service',
    icon: [
        32.245,
        32.245,
        [],
        null,
        'M27.78 0h-.8a.73.73 0 00-.729.729v7.915a.74.74 0 01-.739.739H22.6a.74.74 0 01-.739-.739V.729A.73.73 0 0021.132 0h-.8a4.471 4.471 0 00-4.467 4.466v6.446a4.49 4.49 0 003.208 4.285v5.771a.73.73 0 00.729.729h1.051v9.818a.73.73 0 00.729.729h4.945a.729.729 0 00.729-.729v-9.818h1.052a.73.73 0 00.729-.729v-5.771a4.49 4.49 0 003.208-4.285V4.466A4.468 4.468 0 0027.78 0zM25.8 30.788h-3.487v-9.09H25.8zm4.988-19.876a3.02 3.02 0 01-2.581 2.978.73.73 0 00-.627.722v2.189h-1.052a.73.73 0 000 1.458h1.052v1.98h-7.049v-1.98h1.052a.729.729 0 100-1.458h-1.052v-2.189a.73.73 0 00-.627-.722 3.02 3.02 0 01-2.581-2.978V4.466a3.012 3.012 0 013.009-3.008h.07v7.186a2.2 2.2 0 002.197 2.197h2.912a2.2 2.2 0 002.198-2.197V1.458h.07a3.012 3.012 0 013.008 3.008v6.446zM10.247 0H2.529A2.532 2.532 0 000 2.529v2.876a.73.73 0 00.729.729h2.457v6.498H.729a.73.73 0 00-.729.729v18.155a.73.73 0 00.729.729h11.318a.73.73 0 00.729-.729V13.361a.73.73 0 00-.729-.729H9.59V6.134h2.457a.73.73 0 00.729-.729V2.529A2.53 2.53 0 0010.247 0zm1.072 30.788H1.46V14.091h9.859zM8.132 12.633H4.645V6.135h3.487zm3.187-7.957H1.46V2.53c0-.591.48-1.071 1.07-1.071h3.13v.856a.73.73 0 001.458 0v-.856h3.13c.591 0 1.071.48 1.071 1.071zM8.009 21.71H5.898l1.155-2.561a.73.73 0 10-1.329-.6l-1.62 3.589a.73.73 0 00.664 1.029H6.88l-1.156 2.561a.73.73 0 001.329.6l1.621-3.59a.728.728 0 00-.665-1.028z'
    ]
};
