import $ from 'jquery';
import scroll from './hash-scroll';

$('.accordion').on('shown.bs.collapse', e => {
    scroll(
        $(e.currentTarget)
            .find('[aria-expanded=true]')
            .get(0)
    );
});
