import $ from 'jquery';
import Swiper from 'swiper';

$(() => {
    let partnersSlider = new Swiper('.partners-slider.swiper-container', {
        autoplay: {
            delay: 3000
        },
        loop: true,
        grabCursor: true,
        spaceBetween: 40,
        slidesPerView: 1,
        // slidesPerView: 'auto',
        slidesPerGroup: 1,
        breakpoints: {
            576: {
                slidesPerView: 2,
                // slidesPerGroup: 2
            },
            768: {
                slidesPerView: 3,
                // slidesPerGroup: 3
            },
            1440: {
                slidesPerView: 4,
                // slidesPerGroup: 4
            }
        }
    });
});
