export default {
    prefix: 'custom',
    iconName: 'quality',
    icon: [
        8.22,
        10.35,
        [],
        null,
        'M1.99 8.24l-.66.66a.21.21 0 0 0 .3.3l.66-.66a.21.21 0 1 0-.3-.3zm4.24 0a.21.21 0 0 0-.3.3l.65.66a.21.21 0 0 0 .3 0 .21.21 0 0 0 0-.3zM4.11 9c-.12 0-.21.1-.21.2v.94a.21.21 0 1 0 .42 0V9.2c0-.12-.1-.21-.21-.21zm4.08-2.67a4.56 4.56 0 0 0-3.87-2.55V2.61h.55c.08 0 .16-.05.2-.13l.46-1.05h1.6a.21.21 0 0 0 0-.43H1.09a.21.21 0 0 0 0 .43h1.6l.47 1.05c.04.08.11.13.2.13h.55v1.17A4.53 4.53 0 0 0 .02 6.33a.21.21 0 0 0 .2.3h2.14a1.77 1.77 0 0 0 3.5 0H8a.21.21 0 0 0 .2-.3zm-5.04-4.9h1.92l-.34.75H3.5zm.96 6.33c-.67 0-1.22-.49-1.33-1.13h2.65c-.1.64-.65 1.13-1.32 1.13zM.57 6.21a4.1 4.1 0 0 1 3.54-2c1.47 0 2.8.75 3.54 2zM.39.43h7.43a.21.21 0 0 0 0-.43H.4a.21.21 0 0 0 0 .43z'
    ]
};
