export default {
    prefix: 'custom',
    iconName: 'speech-bubble',
    icon: [
        6.35,
        6.35,
        [],
        null,
        'M4.63 4.233H1.72a.132.132 0 010-.264h2.91a.132.132 0 010 .264zm0-.793H1.72a.132.132 0 010-.265h2.91a.132.132 0 010 .265zm0-.794H1.72a.132.132 0 010-.265h2.91a.132.132 0 010 .265zM3.175 6.35A3.17 3.17 0 011.6 5.928L.212 6.3a.132.132 0 01-.162-.162L.422 4.75A3.17 3.17 0 010 3.175 3.179 3.179 0 013.175 0 3.179 3.179 0 016.35 3.175 3.179 3.179 0 013.175 6.35zm-1.556-.696c.024 0 .047.006.068.018a2.9 2.9 0 001.488.413 2.914 2.914 0 002.91-2.91 2.914 2.914 0 00-2.91-2.91 2.914 2.914 0 00-2.91 2.91c0 .521.142 1.036.413 1.489.018.03.023.067.014.102l-.327 1.22 1.22-.327a.125.125 0 01.034-.005z'
    ]
};
