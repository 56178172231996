import $ from 'jquery';
import Mmenu from 'mmenu-js';

$(() => {
    const menu = new Mmenu(
        'header .navbar-standard #navbar-content',
        {
            iconPanels: true,
            backButton: true,
            wrappers: ['bootstrap'],
            extensions: ['fx-menu-slide', 'border-full', 'theme-dark']
        },
        {
            offCanvas: {
                // clone: true,
                menu: {
                    // insertSelector: '.ccm-page'
                },
                page: {
                    selector: '.ccm-page'
                }
            }
        }
    );

    const api = menu.API;

    $('.navbar-custom-toggler').on('click', () => {
        api.open();
    });
});
