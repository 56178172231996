export default {
    prefix: 'custom',
    iconName: 'switchgear',
    icon: [
        37.434,
        30.995,
        [],
        null,
        'M27.254 13.278h1.701a.786.786 0 100-1.574h-.062V8.102a.787.787 0 10-1.574 0v3.602h-.064a.787.787 0 00-.001 1.574zm-6.259 0h1.702a.787.787 0 100-1.574h-.064V8.102a.787.787 0 10-1.574 0v3.602h-.063a.788.788 0 00-.001 1.574zm-6.258 0h1.702a.787.787 0 100-1.574h-.063V8.102a.788.788 0 00-1.575 0v3.602h-.063a.787.787 0 00-.001 1.574zm-6.259 0h1.702a.787.787 0 100-1.574h-.063V8.102a.788.788 0 00-1.574 0v3.602h-.064a.787.787 0 00-.001 1.574zm25.457 8.118V4.286a.787.787 0 00-.787-.787H4.286a.787.787 0 00-.787.787v17.111c0 .435.352.787.787.787h28.862a.788.788 0 00.787-.788zm-1.574-.787H5.073V5.073h27.288zm4.286 5.073a.788.788 0 00.787-.787V.787A.788.788 0 0036.647 0H.787A.787.787 0 000 .787v24.108c0 .435.353.787.787.787h11.514v3.739H.787a.787.787 0 000 1.574h12.301a.787.787 0 00.787-.787v-4.526h4.054v4.526a.787.787 0 001.575 0v-4.526h4.055v4.526c0 .435.352.787.787.787h12.301a.788.788 0 000-1.574H25.133v-3.739zM1.574 1.574h34.285v22.534H1.574zm26.532 13.621c-.869 0-1.575.706-1.575 1.574 0 .868.706 1.574 1.575 1.574.867 0 1.573-.706 1.573-1.574 0-.868-.706-1.574-1.573-1.574zm-4.686 1.574a1.574 1.574 0 01-1.574 1.574 1.574 1.574 0 01-1.574-1.574 1.574 1.574 0 011.574-1.574 1.574 1.574 0 011.574 1.574zm-6.258 0a1.574 1.574 0 01-1.574 1.574 1.574 1.574 0 01-1.574-1.574 1.574 1.574 0 011.574-1.574 1.574 1.574 0 011.574 1.574zm-6.259 0a1.574 1.574 0 01-1.574 1.574 1.574 1.574 0 01-1.574-1.574 1.574 1.574 0 011.574-1.574 1.574 1.574 0 011.574 1.574z'
    ]
};
